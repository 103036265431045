import { useEffect, useState } from 'react'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { Add, Delete, Inventory2 } from '@mui/icons-material'

import { useTranslation } from '@contexts/translation'
import { onDeleteList, onEditList } from '@api/lists'

import {
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  Button,
  TableBody,
  Stack,
  MenuItem,
  Tooltip,
  Chip,
  IconButton,
  Tabs,
  Tab
} from '@mui/material'

import useTableStyles from '@styles/table/table'
import useChipStyles from '@styles/chip/chip'

import {
  DraggableComponent,
  DroppableComponent,
  onDragEnd
} from '@utils/dragAndDrop'

import PlaceHolder from '@components/common/PlaceHolder'
import CommonPopoverForm from '@components/common/PopoverForm/CommonPopoverForm'
import ActionsPopover from '@components/common/ActionsPopover'
import DeleteModal from '@components/common/DeleteModal'
import { responseHandler } from '@utils/responseHandler'
import { useRouter } from 'next/router'
import CommonModal from '@components/common/Modal/CommonModal'
import AddListForm from './AddListForm.js'

export default function AddTasksListsForm({
  taskLists,
  orgusers,
  fetchListsURL,
  fetchTasksURL
}) {
  const classesTable = useTableStyles()
  const classesChip = useChipStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { dictionary } = useTranslation()

  const [items, setItems] = useState([])
  const [list, setList] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const [isDelete, setIsDelete] = useState(false)
  const [isArchive, setIsArchive] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [isAddListForm, setIsAddListForm] = useState(false)

  const router = useRouter()
  const { id: operationId } = router.query

  useEffect(() => {
    let data = taskLists
    if (activeTab === 0) {
      data = data?.filter(item => !item.is_archived)
    } else if (activeTab === 1) {
      data = data?.filter(item => item.is_archived)
    }
    setItems(data)
  }, [taskLists, activeTab])

  const actionsRows = l => [
    {
      id: 1,
      label: l.is_archived ? dictionary.unarchive : dictionary.archive,
      icon: <Inventory2 color="secondary" />,
      handleClick: () => {
        setList(l)
        setIsArchive(true)
      }
    },
    {
      id: 2,
      label: dictionary.delete,
      icon: <Delete color="error" />,
      handleClick: () => {
        setList(l)
        setIsDelete(true)
      }
    }
  ]

  const deleteList = async () => {
    setIsLoading(true)
    const response = await onDeleteList(list.id)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchListsURL)
        await mutate(fetchTasksURL)
      },
      dictionary,
      msgSuccess: dictionary.list_deleted,
      snackbar: enqueueSnackbar
    })

    setList({})
    setIsDelete(false)
    setIsLoading(false)
  }

  const handleUpdateList = async (id, payload) => {
    setIsLoading(true)
    const response = await onEditList(id, payload)

    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchListsURL)
      },
      dictionary,
      msgSuccess: dictionary.list_updated,
      snackbar: enqueueSnackbar
    })

    setList({})
    setIsArchive(false)
    setIsLoading(false)
  }

  const handleClose = () => {
    setIsAddListForm(false)
  }

  const tabs = [
    {
      label: dictionary.active,
      value: 0
    },
    {
      label: dictionary.archived,
      value: 1
    }
  ]

  const handleChange = (_i, value) => {
    setActiveTab(value)
  }
  return (
    <Grid item xs={12} container spacing={3}>
      <Grid item xs={12}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{
            mb: 1.5,
            '& .MuiTabs-indicator': {
              backgroundColor: 'secondary.main'
            }
          }}
        >
          {tabs.map(item => {
            return (
              <Tab
                key={item.label}
                label={item.label}
                value={item.value}
                data_testid="LinkTab-1bfa11fd-1432-4bbf-b1d7-2c70311c2b85"
              />
            )
          })}
        </Tabs>
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button
          startIcon={<Add />}
          onClick={() => setIsAddListForm(true)}
          data-testid="Button-5928ad8c-68ae-4ba1-8043-25ba33d25335"
        >
          {dictionary.add_new_list}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <TableContainer className={classesTable.container}>
          <Table aria-label="labels-list-dense-table">
            <TableHead>
              <TableRow hover={false}>
                <TableCell />
                <TableCell className={classesTable.headerCell}>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    data-testid="Typography-fbfd18a5-8192-4f56-8cc3-94fd029b01fe"
                  >
                    {dictionary.title}
                  </Typography>
                </TableCell>
                <TableCell align="center" className={classesTable.headerCell}>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    data-testid="Typography-100e4925-5fa2-45b5-8686-1b9feb100d95"
                  >
                    {dictionary.author}
                  </Typography>
                </TableCell>
                {!operationId && (
                  <TableCell align="center" className={classesTable.headerCell}>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      data-testid="Typography-100e4925-5fa2-45b5-8686-1b9feb1012395"
                    >
                      {dictionary.operation}
                    </Typography>
                  </TableCell>
                )}

                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody
              component={DroppableComponent(res =>
                onDragEnd(res, items, setItems, onEditList)
              )}
            >
              {!items?.length && (
                <TableRow hover={false} className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <PlaceHolder
                      src="/Placeholders/Project_list.png"
                      width={350}
                      height={350}
                    />
                  </TableCell>
                </TableRow>
              )}

              {items &&
                items.map((l, index) => (
                  <TableRow
                    key={l.id}
                    className={classesTable.row}
                    component={DraggableComponent(
                      l.id.toString(),
                      index,
                      classesTable.row
                    )}
                  >
                    <TableCell align="right">
                      <CommonPopoverForm
                        style={{ marginLeft: 0 }}
                        variant="changeOptionDark"
                        buttonLabel={l.title}
                        propertyLabel={dictionary.title}
                        defaultValue={l.title || ''}
                        onSubmit={values =>
                          handleUpdateList(l.id, {
                            title: values.textPopover
                          })
                        }
                        data_testid="CommonPopoverForm-768bb47f-7eb3-4019-8c10-a0d13cb86dd7"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title={dictionary.owner_can_see}>
                        <Typography
                          variant="subtitle2"
                          data-testid="Typography-682fb082-4c83-489e-ba97-4dc02fcb718b"
                        >
                          {l?.author || '--'}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    {!operationId && (
                      <TableCell align="center">
                        <Typography
                          variant="subtitle2"
                          data-testid="Typography-682fb082-4c83-489e-ba97-4dc02fcb718b"
                        >
                          {l?.operation?.display || '--'}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell>
                      {l.is_private && (
                        <>
                          <Stack direction="row" alignItems="center">
                            <Chip
                              size="small"
                              className={classesChip.primaryLight}
                              label={l.author_display}
                              data-testid="Chip-abdf0543-8d12-4737-a2f6-bc1f638c80dc"
                            />

                            <span data-testid="span-7df5619b-13a5-4f98-82d5-5d1f7f9d85de">
                              <CommonPopoverForm
                                type="select"
                                multiple
                                style={{ marginLeft: 0 }}
                                buttonLabel={
                                  <IconButton
                                    size="small"
                                    data-testid="IconButton-1f1820c7-4384-45cc-818d-5ccb9d78aba0"
                                  >
                                    <Add color="primary" fontSize="small" />
                                  </IconButton>
                                }
                                propertyLabel={dictionary.followers}
                                defaultValue={l.followers || []}
                                onSubmit={values =>
                                  handleUpdateList(l.id, {
                                    followers: values.selectPopover
                                  })
                                }
                                data_testid="CommonPopoverForm-000404cb-2e9c-4797-95a6-a294241269df"
                              >
                                {orgusers &&
                                  orgusers.results.map(u => (
                                    <MenuItem
                                      key={u.pk}
                                      value={u.pk}
                                      data-testid="MenuItem-19a6a130-fd02-4863-8af9-c7cc53e49f70"
                                    >
                                      {u.display}
                                    </MenuItem>
                                  ))}
                              </CommonPopoverForm>
                            </span>
                          </Stack>
                          <Stack direction="row" flexWrap="wrap">
                            {l.followers.map(f => {
                              const usr =
                                orgusers &&
                                orgusers.results.find(u => u.pk === f)
                              if (usr)
                                return (
                                  <Chip
                                    key={usr.pk}
                                    size="small"
                                    label={usr.display}
                                    data-testid="Chip-e68f7ffe-6266-40b5-84c0-4f7a8b29df21"
                                  />
                                )
                            })}
                          </Stack>
                        </>
                      )}
                    </TableCell>
                    <TableCell className={classesTable.rowActions}>
                      <ActionsPopover actions={actionsRows(l)} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <CommonModal
        title={dictionary.add_new_list}
        open={isAddListForm}
        onClose={handleClose}
        maxWidth={'sm'}
      >
        {isAddListForm && (
          <AddListForm
            operationId={operationId}
            fetchListsURL={fetchListsURL}
            handleClose={handleClose}
          />
        )}
      </CommonModal>

      <DeleteModal
        open={isDelete}
        onClose={() => setIsDelete(false)}
        onConfirm={deleteList}
        description={`${dictionary.delete_list} ${list?.title}?`}
        loading={isLoading}
      />

      <DeleteModal
        open={isArchive}
        onClose={() => setIsArchive(false)}
        onConfirm={() =>
          handleUpdateList(list.id, { is_archived: !list?.is_archived })
        }
        description={`${dictionary.archive_list} ${list?.title}?`}
        loading={isLoading}
      />
    </Grid>
  )
}
