import { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useTranslation } from '@contexts/translation'
import { Grid, MenuItem, Button } from '@mui/material'

import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'
import { onCreateTask, onEditTask } from '@api/tasks'

import DatePicker from '@components/form/DatePicker'
import Select from '@components/common/Select'
import Loader from '@components/common/Loader/Loader'
import Editor from '@components/common/Form/Editor/Editor'

import TextFieldItem from '@components/common/Form/TextFieldItem'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { format } from '@utils/date-fns_functions'
import { useRouter } from 'next/router'
import mutateURLs from '@utils/mutateURLs'
import { responseHandler } from '@utils/responseHandler'

import AutocompleteItem from '@components/common/Autocomplete'
import unformatDate from '@utils/unformatDate'

export default function TaskForm({
  taskId,
  isEdit = false,
  handleClose,
  mutateURLArray = [],
  // labels,
  lotId = null,
  tenantId = null,
  landlordId = null,
  investorId = null,
  fetchDetailModalURL = null,
  projectId = null,
  operations,
  orgusers
}) {
  const { dictionary } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const router = useRouter()

  const { id: operationId } = router.query

  const schema = yup.object().shape({
    title: yup.string().required(dictionary.required),
    description: yup.string(),
    operation: yup.string(),
    assigned_to: yup.array(),
    due_date: yup.string().nullable()
  })

  const {
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
      description: '',
      assigned_to: [],
      due_date: '',
      operation: operationId || ''
    }
  })

  const { data: taskData, isLoading } = useFetchData(
    isEdit && taskId ? API_URL.TASKS.GET_TASK(taskId) : null
  )

  useEffect(() => {
    if (isEdit && taskData) {
      setValue('title', taskData?.title)
      setValue('description', taskData?.description || '')
      if (taskData?.assigned_to) {
        setValue(
          'assigned_to',
          taskData?.assigned_to.map(item => item.id)
        )
      }
      if (taskData?.due_date) {
        setValue('due_date', unformatDate(taskData?.due_date))
      }
      setValue('operation', taskData?.operation.id || '')
    }
  }, [taskData, isEdit])

  const onSubmit = async values => {
    setLoading(true)
    const payload = {
      title: values.title,
      description: values.description,
      assigned_to: values.assigned_to.includes('') ? [] : values.assigned_to,
      operation: lotId ? operationId : values.operation
    }
    if (values.due_date && values.due_date !== '') {
      payload.due_date = format(new Date(values.due_date), 'dd-MM-yyyy')
    } else delete values.due_date
    if (lotId) payload.lots = [Number(lotId)]
    if (tenantId) payload.tenant = Number(tenantId)
    if (landlordId) payload.landlord = Number(landlordId)
    if (investorId) payload.investor = Number(investorId)

    let response
    if (isEdit) {
      response = await onEditTask(taskId, payload)
    } else {
      // response = await onCreateTask(organization, values)
      response = await onCreateTask(payload)
    }
    responseHandler({
      response,
      callback: async () => await mutateURLs(mutateURLArray),
      dictionary,
      msgSuccess: isEdit ? dictionary.task_updated : dictionary.task_created,
      snackbar: enqueueSnackbar,
      setError
    })
    setLoading(false)
    handleClose && handleClose()
  }

  if (isLoading) return <Loader />
  return (
    <Grid item xs={12}>
      <form data-cy={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {/* Title */}
          <Grid item xs={12}>
            <Controller
              name="title"
              control={control}
              render={props => (
                <TextFieldItem
                  required
                  placeholder={dictionary.task_title}
                  label={dictionary.task_title}
                  errorMessage={errors.title?.message}
                  {...props}
                  data_testid="TextFieldItem-4c0fd0a2-6c0d-40b2-81fe-914c4194d0cb"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Editor
              name="description"
              control={control}
              errorMessage={errors.description?.message}
              init={{
                height: 250,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image',
                  'charmap print preview anchor help',
                  'searchreplace visualblocks code',
                  'insertdatetime media table paste wordcount'
                ],

                toolbar:
                  'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <AutocompleteItem
              multiple
              name="assigned_to"
              label={dictionary.assignedTo}
              control={control}
              defaultValue={[]}
              elements={{
                items: orgusers?.results || [],
                id: 'id',
                display: 'display'
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              name="due_date"
              label={dictionary.due_date}
              variant="outlined"
              fullWidth
              control={control}
              error={errors.due_date}
              errorMessage={errors.due_date && errors.due_date.message}
              clearable
              data_testid="DatePicker-d926cea6-b456-4f44-aeeb-7cd78831c35c"
            />
          </Grid>

          {!lotId && !projectId && (
            <Grid item xs={12} md={6}>
              <Select
                fullWidth
                name="operation"
                label={dictionary.project}
                variant="outlined"
                control={control}
                disabled={isEdit && taskData.lots.length}
                errorMessage={errors.operation?.message}
                data_testid="Select-de06ba95-2649-4ade-b685-1a61e4312801"
              >
                <MenuItem
                  value=""
                  data-testid="MenuItem-e687a9b9-68ef-4680-8a4d-437ddfb348bb"
                >
                  {'----------'}
                </MenuItem>
                {operations?.results.map(({ id, display }) => (
                  <MenuItem
                    key={id}
                    value={id}
                    data-testid="MenuItem-fa6f76f9-abff-4cfe-8ed1-e41b5b2dc0e7"
                  >
                    {display}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Button
              color="error"
              variant="outlined"
              onClick={handleClose}
              data-testid="Button-750d6977-2b54-4338-9b91-a36ec3edd187"
            >
              {dictionary.cancel}
            </Button>
            <Button
              disabled={loading}
              type="submit"
              color="primary"
              data-testid="Button-6ba2332f-f476-4437-9c61-39fbd416379c"
            >
              {dictionary.save}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}
