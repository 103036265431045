import React, { useState } from 'react'
import CommonPagination from '@components/common/Pagination'

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Typography,
  Checkbox,
  Chip,
  Avatar,
  MenuItem,
  IconButton,
  CircularProgress,
  Tooltip,
  Modal,
  Box,
  TableSortLabel,
  TableFooter,
  AvatarGroup
} from '@mui/material'
import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import useChipStyles from '@styles/chip/chip'
import { DateRange, Delete, Edit, Add } from '@mui/icons-material'
import ActionsPopover from '@components/common/ActionsPopover'
import { onEditTask } from '@api/tasks'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import CommonPopoverForm from '@components/common/PopoverForm/CommonPopoverForm'
import format from 'date-fns/format'
import PlaceHolder from '@components/common/PlaceHolder'
import unformatDate from '@utils/unformatDate'
import { useTheme } from '@mui/material/styles'
import {
  DraggableComponent,
  DroppableComponent,
  onDragEnd
} from '@utils/dragAndDrop'
import { responseHandler } from '@utils/responseHandler'
import { setItemsSort } from '@utils/sortTables'

const ViewTaskDescription = ({ open, onClose, title, desc }) => {
  const theme = useTheme()
  const { dictionary } = useTranslation()

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: theme.spacing(50),
          bgcolor: theme.palette.elements.white,
          boxShadow: 24,
          p: 3,
          //Idea : For better looks, create outter scroll bar OR remove borderRadius
          overflowY: 'auto',
          maxHeight: '80vh',
          outline: 'none',
          borderRadius: theme.spacing(1)
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ textDecoration: 'underline', mb: 1 }}
          data-testid="Typography-33865a02-1f13-4970-a32a-5ca8a4227f34"
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          data-testid="Typography-5cfb2d8a-1a7d-4af1-bace-3970cc4dcb03"
        >
          {(!!desc && <div dangerouslySetInnerHTML={{ __html: desc }} />) || (
            <i>{dictionary.description_empty}</i>
          )}
        </Typography>
      </Box>
    </Modal>
  )
}

const TableView = ({
  isPagination,
  items,
  setItems,
  isLoadingData,
  orgusers,
  fetchTasksURL,
  setIsEdit,
  setIsDelete,
  setTaskId,
  queries,
  count = 0,
  setQueries = () => {},
  page = 0,
  setPage = () => {},
  rowsPerPage = 0,
  setRowsPerPage = () => {},
  projectId = null,
  isDetailModal = false,
  lotId = null
}) => {
  const { dictionary } = useTranslation()
  const classesTable = useTableStyles()
  const classesChip = useChipStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [taskDescription, setTaskDescription] = useState({
    open: false,
    title: null,
    desc: null
  })
  const [isLoading, setIsLoading] = useState(false)

  const showProject =
    (!lotId && isDetailModal) || (!projectId && !isDetailModal)

  const getDeadlineClass = it => {
    if ((it.is_due_today || it.is_overdue) && !it.is_completed)
      return classesChip.errorLight
    else if (!it.is_due_today && !it.is_overdue && !it.is_completed)
      return classesChip.primaryLight
    else if (it.is_completed) return classesChip.successLight
  }

  const getChipTitle = task => {
    if (task.supplier) return dictionary.supplier
    if (task.tenant) return dictionary.tenant
    if (task.landlord) return dictionary.landlord
    if (task.investor) return dictionary.investor
    if (task.lots.length) return dictionary.lots
    else return null
  }

  const getChipToolTip = task => {
    if (task.supplier) return task.supplier.display
    if (task.tenant) return task.tenant.display
    if (task.landlord) return task.landlord.display
    if (task.investor) return task.investor.display
    if (task.lots.length) return task.lots[0].display
    else return null
  }

  const actionsRows = item => [
    {
      id: 1,
      label: dictionary.edit,
      icon: <Edit color="secondary" />,
      handleClick: () => {
        setTaskId(item.id)
        setIsEdit(true)
      }
    },
    {
      id: 2,
      label: dictionary.delete,
      icon: <Delete color="error" />,
      handleClick: () => {
        setTaskId(item.id)
        setIsDelete(true)
      }
    }
  ]

  const handleUpdateTask = async (id, payload) => {
    setIsLoading(true)
    const response = await onEditTask(id, payload)

    responseHandler({
      response,
      callback: async () => await mutate(fetchTasksURL),
      dictionary,
      msgSuccess: dictionary.task_updated,
      snackbar: enqueueSnackbar
    })
    setIsLoading(false)
  }
  const [sort, setSort] = useState('asc')
  const [sortBy, setSortBy] = useState()

  return (
    <>
      <TableContainer className={classesTable.container}>
        <Table aria-label="collaboration-tasks-dense-table">
          <TableHead>
            <TableRow hover={false}>
              <TableCell />
              <TableCell align="center" className={classesTable.headerCell}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  data-testid="Typography-382b0eac-aee6-418e-a8c4-afff68265da9"
                >
                  {dictionary.done}
                </Typography>
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                <TableSortLabel
                  active={sortBy === 'due_date'}
                  direction={sort}
                  onClick={() =>
                    setItemsSort('due_date', setSortBy, sort, setSort, setItems)
                  }
                >
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    data-testid="Typography-99ecb747-5c73-4276-8009-4467ee726fd5"
                  >
                    {dictionary.due_date}
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                <TableSortLabel
                  active={sortBy === 'title'}
                  direction={sort}
                  onClick={() =>
                    setItemsSort('title', setSortBy, sort, setSort, setItems)
                  }
                >
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    data-testid="Typography-2eac4e86-3213-4068-96ca-8ab70d90fc1c"
                  >
                    {dictionary.title}
                  </Typography>
                </TableSortLabel>
              </TableCell>
              {showProject && (
                <TableCell className={classesTable.headerCell}>
                  <TableSortLabel
                    active={sortBy === 'operation'}
                    direction={sort}
                    onClick={() =>
                      setItemsSort(
                        'operation',
                        setSortBy,
                        sort,
                        setSort,
                        setItems
                      )
                    }
                  >
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      data-testid="Typography-4461a9f4-add4-4572-811a-f394985c7686"
                    >
                      {dictionary.project}
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              )}

              <TableCell className={classesTable.headerCell}>
                <TableSortLabel
                  active={sortBy === 'list_task'}
                  direction={sort}
                  onClick={() =>
                    setItemsSort(
                      'list_task',
                      setSortBy,
                      sort,
                      setSort,
                      setItems
                    )
                  }
                >
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    data-testid="Typography-6d63d198-236c-45ac-85bc-c67bd614d361"
                  >
                    {dictionary.list}
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell className={classesTable.headerCell}>
                <TableSortLabel
                  active={sortBy === 'assigned_to'}
                  direction={sort}
                  onClick={() =>
                    setItemsSort(
                      'assigned_to',
                      setSortBy,
                      sort,
                      setSort,
                      setItems
                    )
                  }
                >
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    data-testid="Typography-a916d62a-eaf7-4156-b4f9-4e35f4ab772a"
                  >
                    {dictionary.assigned_to}
                  </Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>{dictionary.created_in}</TableCell>
              {!isDetailModal && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody
            component={DroppableComponent(res =>
              onDragEnd(res, items, setItems, onEditTask)
            )}
          >
            {isLoadingData && (
              <TableRow className={classesTable.row}>
                <TableCell align="center" colSpan={'100%'}>
                  <CircularProgress color="secondary" />
                </TableCell>
              </TableRow>
            )}

            {!isLoadingData && !items?.length && (
              <TableRow className={classesTable.row}>
                <TableCell align="center" colSpan={'100%'}>
                  <PlaceHolder
                    src="/Placeholders/Keysy/Task.png"
                    width={'500px'}
                    height={'450px'}
                  />
                </TableCell>
              </TableRow>
            )}

            {!isLoadingData && items?.length > 0 && (
              <>
                {items.map((it, index) => (
                  <TableRow
                    hover={false}
                    key={it.id}
                    component={DraggableComponent(
                      it.id.toString(),
                      index,
                      it.is_completed
                        ? classesTable.rowTotalSecondary
                        : classesTable.row,
                      it.is_completed ? '#eefef1' : '#fff'
                    )}
                  >
                    <TableCell align="center">
                      <Checkbox
                        size="small"
                        disabled={isLoading}
                        checked={it.is_completed || false}
                        onChange={e => {
                          e.preventDefault()
                          e.stopPropagation()
                          handleUpdateTask(it.id, {
                            is_completed: !it.is_completed
                          })
                        }}
                        data-testid="Checkbox-100e58a5-84b2-438a-b3a1-4ed6f91ab916"
                      />
                    </TableCell>
                    <TableCell>
                      <CommonPopoverForm
                        type="calendar"
                        clearable
                        style={{ marginLeft: '0' }}
                        buttonLabel={
                          it.due_date ? (
                            <Chip
                              size="small"
                              className={getDeadlineClass(it)}
                              label={it.due_date}
                              sx={{ cursor: 'pointer' }}
                              data-testid="Chip-c3f4ce79-2f29-460c-9328-bf91cf6f2192"
                            />
                          ) : (
                            <DateRange color="primary" fontSize="small" />
                          )
                        }
                        propertyLabel={dictionary.due_date}
                        defaultValue={
                          (it.due_date && unformatDate(it.due_date)) || ''
                        }
                        onSubmit={values =>
                          handleUpdateTask(it.id, {
                            due_date:
                              (values.calendarPopover &&
                                format(
                                  new Date(values.calendarPopover),
                                  'dd-MM-yyyy'
                                )) ||
                              null
                          })
                        }
                        data_testid="CommonPopoverForm-bcd9afee-d113-44fc-b767-73a44d8b2c33"
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={`${dictionary.created_by} ${it?.creator?.display}`}
                        sx={{ marginLeft: 0 }}
                      >
                        <Typography
                          variant={it.description ? 'linkOption' : 'subtitle2'}
                          sx={{
                            textDecoration: it.is_completed
                              ? 'line-through'
                              : ''
                          }}
                          onClick={() =>
                            !!it.description &&
                            setTaskDescription({
                              open: true,
                              title: it.title,
                              desc: it.description
                            })
                          }
                          data-testid="Typography-94721365-9c18-4b03-b34c-00f12240d19b"
                        >
                          {it.title}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    {showProject && (
                      <TableCell>
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          data-testid="Typography-d2a2993d-f54e-4ee8-b4c4-f7540beaccf5"
                        >
                          {it.operation?.display}
                        </Typography>
                      </TableCell>
                    )}

                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        data-testid="Typography-de619935-6b57-4940-8ecb-0b31ca43bdc1"
                      >
                        {it.list_task ? it.list_task.display : '---'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <CommonPopoverForm
                        type="select"
                        style={{ marginLeft: '0' }}
                        buttonLabel={
                          it.assigned_to.length === 0 ? (
                            <IconButton
                              size="small"
                              data-testid="IconButton-30ac3388-3b3e-48fa-8e96-725b000d1966"
                            >
                              <Add color="primary" fontSize="small" />
                            </IconButton>
                          ) : (
                            <AvatarGroup max={5}>
                              {it.assigned_to.map((user, index) => (
                                <Tooltip title={user.display} key={index}>
                                  <Avatar />
                                </Tooltip>
                              ))}
                            </AvatarGroup>
                          )
                        }
                        propertyLabel={dictionary.assigned_to}
                        defaultValue={
                          it.assigned_to[0]?.id
                            ? it.assigned_to.map(item => item.id)
                            : []
                        }
                        multiple={true}
                        onSubmit={values => {
                          values.selectPopover[0] === ''
                            ? handleUpdateTask(it.id, {
                                assigned_to: []
                              })
                            : handleUpdateTask(it.id, {
                                assigned_to: values.selectPopover
                              })
                        }}
                        users={orgusers?.results}
                        data_testid="CommonPopoverForm-310b20af-af61-48fa-8641-88483471de8b"
                      >
                        <MenuItem
                          value={''}
                          data-testid="MenuItem-3bc89af4-6f55-482c-8c6b-e1d61457e721"
                        >
                          ----------
                        </MenuItem>
                        {orgusers &&
                          orgusers.results.map(u => (
                            <MenuItem
                              key={u.id}
                              value={u.id}
                              data-testid="MenuItem-6507f508-0501-4aed-bdb9-e487984a2402"
                            >
                              {u.display}
                            </MenuItem>
                          ))}
                      </CommonPopoverForm>
                    </TableCell>
                    {!isDetailModal && (
                      <TableCell>
                        <Tooltip title={getChipToolTip(it)}>
                          {getChipTitle(it) ? (
                            <Chip
                              size="small"
                              className={classesChip.secondary}
                              label={getChipTitle(it)}
                              data-testid="Chip-5ebb63a6-823e-451b-9ca6-b38ac1b453bf"
                            />
                          ) : (
                            <></>
                          )}
                        </Tooltip>
                      </TableCell>
                    )}

                    <TableCell className={classesTable.rowActions}>
                      <ActionsPopover actions={actionsRows(it)} />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
          {!isLoadingData && isPagination && (
            <TableFooter className={classesTable.footer}>
              <CommonPagination
                queries={queries}
                setQueries={setQueries}
                count={count}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </TableFooter>
          )}
        </Table>
      </TableContainer>

      <ViewTaskDescription
        open={taskDescription.open}
        onClose={() =>
          setTaskDescription({ open: false, title: null, desc: null })
        }
        title={taskDescription.title}
        desc={taskDescription.desc}
      />
    </>
  )
}

export default TableView
